import isByteLength from './isByteLength.js';
import isFQDN from './isFQDN.js';
import isIP from './isIP.js';

/* eslint-disable no-control-regex */
const splitNameAddress = /^([^\x00-\x1F\x7F-\x9F\cX]+)</i;
const emailUserPart = /^[a-z\d!#$%&'*+\-/=?^_`{|}~]+$/i;
const gmailUserPart = /^[a-z\d]+$/;
const quotedEmailUser =
  /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f]))*$/i;
const emailUserUtf8Part = /^[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;
const quotedEmailUserUtf8 =
  /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
const defaultMaxEmailLength = 254;
/* eslint-enable no-control-regex */

/**
 * Validate display name according to the RFC2822: https://tools.ietf.org/html/rfc2822#appendix-A.1.2
 * @param {String} displayName
 */
function validateDisplayName(displayName: string) {
  const displayNameWithoutQuotes = displayName.replace(/^"(.+)"$/, '$1');
  // display name with only spaces is not valid
  if (!displayNameWithoutQuotes.trim()) {
    return false;
  }

  // check whether display name contains illegal character
  const containsIllegal = /[.";<>]/.test(displayNameWithoutQuotes);
  if (containsIllegal) {
    // if contains illegal characters,
    // must to be enclosed in double-quotes, otherwise it's not a valid display name
    if (displayNameWithoutQuotes === displayName) {
      return false;
    }

    // the quotes in display name must start with character symbol \
    const allStartWithBackSlash =
      displayNameWithoutQuotes.split('"').length === displayNameWithoutQuotes.split('\\"').length;
    if (!allStartWithBackSlash) {
      return false;
    }
  }

  return true;
}

interface IsEmailOptions {
  allowDisplayName?: boolean;
  requireDisplayName?: boolean;
  allowUtf8LocalPart?: boolean;
  requireTld?: boolean;
  blacklistedChars?: string;
  ignoreMaxLength?: boolean;
  hostBlacklist?: string[];
  domainSpecificValidation?: boolean;
  allowIpDomain?: boolean;
}

export default function isEmail(
  str: string,
  {
    allowDisplayName = false,
    requireDisplayName = false,
    allowUtf8LocalPart = true,
    requireTld = true,
    blacklistedChars = '',
    ignoreMaxLength = false,
    hostBlacklist = [],
    domainSpecificValidation = false,
    allowIpDomain = false,
  }: IsEmailOptions = {},
) {
  if (requireDisplayName || allowDisplayName) {
    const displayEmail = str.match(splitNameAddress);
    if (displayEmail) {
      let displayName = displayEmail[1];

      // Remove display name and angle brackets to get email address
      // Can be done in the regex but will introduce a ReDOS (See  #1597 for more info)
      str = str.replace(displayName, '').replace(/(^<|>$)/g, '');

      // sometimes need to trim the last space to get the display name
      // because there may be a space between display name and email address
      // eg. myname <address@gmail.com>
      // the display name is `myname` instead of `myname `, so need to trim the last space
      if (displayName.endsWith(' ')) {
        displayName = displayName.substring(0, displayName.length - 1);
      }

      if (!validateDisplayName(displayName)) {
        return false;
      }
    } else if (requireDisplayName) {
      return false;
    }
  }
  if (!ignoreMaxLength && str.length > defaultMaxEmailLength) {
    return false;
  }

  const parts = str.split('@');
  const domain = parts.pop()!;
  const lowerDomain = domain.toLowerCase();

  if (hostBlacklist.includes(lowerDomain)) {
    return false;
  }

  let user = parts.join('@');

  if (domainSpecificValidation && (lowerDomain === 'gmail.com' || lowerDomain === 'googlemail.com')) {
    /*
      Previously we removed dots for gmail addresses before validating.
      This was removed because it allows `multiple..dots@gmail.com`
      to be reported as valid, but it is not.
      Gmail only normalizes single dots, removing them from here is pointless,
      should be done in normalizeEmail
    */
    user = user.toLowerCase();

    // Removing sub-address from username before gmail validation
    const username = user.split('+')[0];

    // Dots are not included in gmail length restriction
    if (!isByteLength(username.replace(/\./g, ''), { min: 6, max: 30 })) {
      return false;
    }

    const userParts = username.split('.');
    for (let i = 0; i < userParts.length; i++) {
      if (!gmailUserPart.test(userParts[i])) {
        return false;
      }
    }
  }

  if (ignoreMaxLength === false && (!isByteLength(user, { max: 64 }) || !isByteLength(domain, { max: 254 }))) {
    return false;
  }

  if (!isFQDN(domain, { requireTld })) {
    if (!allowIpDomain) {
      return false;
    }

    if (!isIP(domain)) {
      if (!domain.startsWith('[') || !domain.endsWith(']')) {
        return false;
      }

      const noBracketdomain = domain.substring(1, domain.length - 2);

      if (noBracketdomain.length === 0 || !isIP(noBracketdomain)) {
        return false;
      }
    }
  }

  if (user[0] === '"') {
    user = user.slice(1, user.length - 1);
    return allowUtf8LocalPart ? quotedEmailUserUtf8.test(user) : quotedEmailUser.test(user);
  }

  const pattern = allowUtf8LocalPart ? emailUserUtf8Part : emailUserPart;

  const userParts = user.split('.');
  for (let i = 0; i < userParts.length; i++) {
    if (!pattern.test(userParts[i])) {
      return false;
    }
  }
  if (blacklistedChars) {
    if (user.search(new RegExp(`[${blacklistedChars}]+`, 'g')) !== -1) return false;
  }

  return true;
}

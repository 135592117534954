import {
  PASSWORD_IS_NOT_STRONG,
  USER_FORM_EMAIL,
  USER_FORM_FIRST_NAME,
  USER_FORM_IS_ACTIVE,
  USER_FORM_LAST_NAME,
  USER_FORM_PASSWORD,
  USER_FORM_PASSWORD_CHECK,
  USER_FORM_ROLE,
  USER_FORM_USERNAME,
  USER_VALIDATION_EMAIL_IS_INVALID,
  USER_VALIDATION_PASSWORD_IS_REQUIRED,
  USER_VALIDATION_PASSWORDS_DO_NOT_MATCH,
  USER_VALIDATION_ROLE_IS_REQUIRED,
  USER_VALIDATION_USERNAME_IS_REQUIRED,
} from '@asaprint/asap/locales/client.js';
import { Role } from '@asaprint/common/access.js';
import AutocompleteField, { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import SwitchField from '@engined/client/components/forms/fields/SwitchField.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { FIELD_IS_REQUIRED } from '@engined/client/locales.js';
import isEmail from '@engined/core/validators/isEmail.js';
import isLength from '@engined/core/validators/isLength.js';
import isStrongPassword from '@engined/core/validators/isStrongPassword.js';
import { FormControlLabel, Grid } from '@mui/material';
import React from 'react';

export interface FormValues {
  id?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  active: boolean;
  role: Option;
  moneyUser: Option;
  color: string;
}

interface OwnProps {
  passwordRequired?: boolean;
  moneyUserOptions: Option[];
}

type Props = OwnProps;

export const roleOptions = Object.keys(Role)
  .filter((r) => Role[r] !== Role.Developer)
  .map((r) => ({
    label: Role[r],
    value: Role[r],
  }));

const UserForm: React.FunctionComponent<Props> = ({ moneyUserOptions, passwordRequired = false }) => {
  const { t } = useLocale();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          fullWidth
          label={t(USER_FORM_FIRST_NAME)}
          name="firstName"
          autoComplete="first-name"
          maxLength={100}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          fullWidth
          label={t(USER_FORM_LAST_NAME)}
          name="lastName"
          autoComplete="last-name"
          maxLength={100}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField variant="outlined" required fullWidth label={t(USER_FORM_EMAIL)} name="email" autoComplete="email" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          label={t(USER_FORM_USERNAME)}
          type="text"
          name="username"
          autoComplete="username"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required={passwordRequired}
          fullWidth
          name="password"
          label={t(USER_FORM_PASSWORD)}
          type="password"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required={passwordRequired}
          fullWidth
          name="passwordConfirmation"
          label={t(USER_FORM_PASSWORD_CHECK)}
          type="password"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteField name="role" options={roleOptions} inputLabel={t(USER_FORM_ROLE)} />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6}>
        <FormControlLabel control={<SwitchField name="active" />} label={t(USER_FORM_IS_ACTIVE)} />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6}>
        <AutocompleteField
          name="moneyUser"
          options={moneyUserOptions}
          inputLabel="Používateľ z Money S4"
          inputHelperText="Vyberte používateľa z Money S4 systému, na ktorého sa má tento používateľ namapovať."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField variant="outlined" fullWidth name="color" label="Farba" type="color" />
      </Grid>
    </Grid>
  );
};

UserForm.displayName = 'UserForm';

export default UserForm;

export function validate(
  values: FormValues,
  t: LocaleContextValue['t'],
  { passwordRequired = false }: { passwordRequired?: boolean } = {},
) {
  const errors: FormErrors<FormValues> = {};
  if (!values.email) {
    errors.email = t(FIELD_IS_REQUIRED);
  } else if (!isEmail(values.email)) {
    errors.email = t(USER_VALIDATION_EMAIL_IS_INVALID);
  }

  if (passwordRequired || values.password || values.passwordConfirmation) {
    if (!values.password) {
      errors.password = t(USER_VALIDATION_PASSWORD_IS_REQUIRED);
    } else if (!isStrongPassword(values.password)) {
      errors.password = t(PASSWORD_IS_NOT_STRONG);
    }

    if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t(USER_VALIDATION_PASSWORDS_DO_NOT_MATCH);
    }
  }

  if (!values.username) {
    errors.username = t(USER_VALIDATION_USERNAME_IS_REQUIRED);
  } else if (!isLength(values.username, { min: 3 })) {
    errors.username = 'Prihlasovacie meno je krátke - použite aspoň 3 znaky';
  } else if (isEmail(values.username)) {
    errors.username = 'Používateľské meno nemôže byť e-mailová adresa';
  }

  if (!values.role) {
    errors.role = t(USER_VALIDATION_ROLE_IS_REQUIRED);
  }

  if (!values.color) {
    errors.color = t(FIELD_IS_REQUIRED);
  }

  return errors;
}

interface IsLengthOptions {
  min?: number;
  max?: number;
}

export default function isLength(str: string, { min = 0, max }: IsLengthOptions = {}) {
  const surrogatePairs = str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
  const len = str.length - surrogatePairs.length;
  return len >= min && (typeof max === 'undefined' || len <= max);
}

import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';

const MemoMuiSwitch = React.memo(MuiSwitch);

interface OwnProps {
  name: string;
}

type Props = OwnProps & SwitchProps;

const SwitchField: React.FunctionComponent<Props> = ({ name, onBlur, onChange, inputProps, ...rest }) => {
  const { field } = useController({
    name,
  });

  const onBlurCallback = useEventCallback<SwitchProps['onBlur']>((event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  });

  const onChangeCallback = useEventCallback<SwitchProps['onChange']>((event, ...rest) => {
    field.onChange(event);
    if (onChange) {
      onChange(event, ...rest);
    }
  });

  const customInputProps = useMemo<SwitchProps['inputProps']>(
    () => ({
      name,
      ...inputProps,
    }),
    [inputProps, name],
  );

  return (
    <MemoMuiSwitch
      checked={field.value}
      onBlur={onBlurCallback}
      onChange={onChangeCallback}
      inputProps={customInputProps}
      name={field.name}
      inputRef={field.ref}
      {...rest}
    />
  );
};

SwitchField.displayName = 'SwitchField';

export default React.memo<Props>(SwitchField);
